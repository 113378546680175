<template>
<v-app>
    <div>
        <v-toolbar
                dense
                color="primary"
                dark
                extended
                extension-height="120"
                flat
        >
       
            <v-toolbar-title  slot="extension" style="margin-left: 8px !important"  >                               

                <h4>Atualização de Contato</h4> 
                <h6>Telefone(s): {{this.cliente_select.Telefone}} - {{this.cliente_select.Celular}} </h6> 
                <h6>{{this.cliente_select.Email}}</h6> 

                <v-layout row >
                    <v-flex wrap>
                        <v-btn outline small fab color="white" to="/home" class="outine-2">
                              <i class="fa fa-home fa-2x" ></i>
                        </v-btn>
                    </v-flex>
                   

                    
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>

        <v-container>
         <v-container grid-list-md>  
            <v-form ref="form"  lazy-validation> 
            <v-layout wrap>

              
                <v-flex xs12 md2 >
                    <v-text-field v-model="cliente_update.PrefixoTelefone" minlength="2" v-mask="'##'"  label="DDD"   box></v-text-field>
                </v-flex>


                <v-flex xs12 sm4>
                    <v-text-field v-model="cliente_update.Telefone" label="Telefone Fixo" minlength="6"  box></v-text-field>
                </v-flex>


                <v-flex xs12 sm2>
                    <v-text-field v-model="cliente_update.PrefixoCelular" minlength="2" v-mask="'##'"   label="DDD"    box></v-text-field>
                </v-flex>


                <v-flex xs12 sm4>
                    <v-text-field v-model="cliente_update.Celular" minlength="6"   label="Celular"    box></v-text-field>
                </v-flex>

                  <v-flex xs12>
                    <v-text-field value="" v-model="cliente_update.Email" label="Email"  box></v-text-field>
                </v-flex>


               
            </v-layout>
            </v-form>

                 <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text flat
                        @click="finalizar" 
                    >
                        Sair
                    </v-btn>
                    <v-btn
                        color="primary"
                        :loading="btn_load"
                        :disabled="btn_load"
                        text
                        @click="enviarAtualizacao"
                    >
                        Confirmar
                    </v-btn>
            </v-card-actions>
        </v-container> 
        </v-container>

        <v-dialog v-model="finish" persistent max-width="340">
            <v-card id="print">
                <v-card-title class="headline">Mensagem</v-card-title>
                <v-card-text v-if="this.load_sorteio == false">{{msg_finish}}</v-card-text>
                 <v-card-text v-if="this.load_sorteio == true">
                
                <span> 👏👏👏 Parabéns, seus dados foram atualizados com sucesso! 👏👏👏 </span><br/> 
                <span> BOA SORTE </span><br/>
                <span> Você está participando da PROMOÇÃO PAI PREVIDA É TUDO DE BOM </span><br/>

                
                <span style="font-weight:bold;"> O número do seu cupom é: {{this.volante}} </span><br/>
                <span> O sorteio será realizado no dia 02 de Setembro de 2022.</span><br/>
                <span> Em até 24h você receberá um SMS com o número do seu cupom.</span>

                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" flat v-if="this.load_sorteio == true" v-print="'#print'" >Imprimir</v-btn>

                <v-btn color="green darken-1" flat @click="finalizar" >OK</v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>




    </div>




</v-app>
</template>

<script>
import { http } from '../../services/config'
import authHeader from '../../services/auth-header';
import { mapGetters, mapActions } from 'vuex';
import Promotor from '../../services/promotor'

    export default {
       
        computed:{
            ...mapGetters([
                    'lista_contratos' , 'cliente_select',  'contratoselecionado'
                ]),


        },

        mounted() {
            if(this.cliente_select === undefined | this.isEmptyObject(this.cliente_select)){
                this.$router.push('/home')

            }
        },

       

        data() {
            return {
                   token_promotor: '',
                   vl_cont_antigo: '',
                   finish: false,
                   msg_finish: '',
                   showload_e: false,
                   btn_load: false,
                   cep: '',
                   bairro: '',
                   volante: '',
                   participante:{
                            contrato: "",
                            cpf: "",
                            valor: "",
                            acao: "",
                            titular: "",
                            telefone: "",
                   },
                   cidade: '',
                   uf: '',
                    ev:{
                        key:'',
                        token:'',
                        evento: '',
                        valorantigo: '',
                        valornovo: '',
                        contrato: ''
                        },
                    cliente_update: { 
                            PrefixoTelefone: "",
                            Telefone: "",
                            PrefixoCelular: "",
                            Celular: "",
                            Email: "",
                        },


                    rules: [
                        value => !!value || 'Required.',
                    ],


            }
        },
      

        methods:{
       
                pontosTelefone(){
                    const obj = {};
                    obj.key = sessionStorage.getItem('token');
                    obj.contrato = sessionStorage.getItem('contrato_token')
                    obj.token = "";
                    obj.evento = "ATUALIZAÇÃO DE TELEFONE"
                    obj.valorantigo = "Celular:"+this.cliente_select.PrefixoCelular+""+this.cliente_select.Celular +" Telefone:"+this.cliente_select.PrefixoTelefone+""+this.cliente_select.Telefone;
                    obj.valornovo = "Celular:"+this.cliente_update.PrefixoCelular+""+this.cliente_update.Celular +" Telefone:"+this.cliente_update.PrefixoTelefone+""+this.cliente_update.Telefone;

                    Promotor.incluirPontoTokenTelefone(obj).then(response => {
                            if(response.status = 200){
                                this.eventos = response.data;
                            }
                        }).catch(e => {
                        }).finally(() => {
                            //this.finalizar();
                    });
                            

                },

                pontosEmail(){
                        const objtwo = {};

                        objtwo.key = sessionStorage.getItem('token');
                        objtwo.token = "";
                        objtwo.contrato = sessionStorage.getItem('contrato_token')
                                           
                                                objtwo.evento = "ATUALIZAÇÃO DE EMAIL"
                                                objtwo.valorantigo = "EMAIL:"+this.cliente_select.Email;
                                                objtwo.valornovo = "EMAIL:"+this.cliente_update.Email;
                                                
                                                 Promotor.incluirPontoTokenEmail(objtwo).then(response => {
                                                    if(response.status = 200){
                                                    this.eventos = response.data;
                                                    }
                                                }).catch(e => {
                                                }).finally(() => {
                                                    //this.finalizar();
                                                });

                                            
                },
               
                isEmptyObject(obj) {
                        var name;

                        for (name in obj) {
                            return false;
                        }
                        return true;
                
                 },

               particicparSorteio(cliente_update){

                   this.load_sorteio = false
                   this.participante.contrato = sessionStorage.getItem('contrato_token')
                   this.participante.cpf = this.cliente_select.InscricaoAtiva;
                   this.participante.titular = this.cliente_select.NomeCompleto;
                   this.participante.acao = 'AC';
                   this.participante.telefone = cliente_update.PrefixoCelular + cliente_update.Celular;
                


                    Promotor.salvaVolante(this.participante).then(response => {
                        this.load_sorteio = true;

                        if(response.status = 200){
                                if(response.data != 'ERRO'){
                                    this.volante = response.data;
                                    this.finish = true;
                                }else{
                                    this.load_sorteio = false

                                }
                            }
                            }).catch(e => {
                            }).finally(() => {
                    });

               },

                
                enviarAtualizacao() {
                    if (this.$refs.form.validate()) {
                    this.btn_load = true, 
                    this.cliente_update.Id = this.cliente_select.Id    
                    let aux = false;  

                    this.cliente_select.PrefixoCelular =  this.cliente_update.PrefixoCelular; 
                    this.cliente_select.Celular =  this.cliente_update.Celular;   
                    this.cliente_select.PrefixoTelefone =  this.cliente_update.PrefixoTelefone; 
                    this.cliente_select.Telefone =  this.cliente_update.Telefone;        
                    this.cliente_select.Email =  this.cliente_update.Email;  
                   

                    http.post('ClienteDIO/Atualizar/', this.cliente_select, {
                            headers: authHeader()
                        }).then(response =>{
                         
                            if(response.data.Status === 0){
                                    aux = true;                  
                                    this.msg_finish = "Parabéns, Seu contato foi atualizado sucesso!"

                                }else{
                                    this.msg_finish = "Ops, Informação não pode ser atualizado no momento, verifique as informações ou tente novamente mais tarde."
                                }
                                setTimeout(() => (this.btn_load = false, this.finish = true), 1500)
                            })
                            .catch(function (error) {
                                    this.msg_finish = "Ops, Servidor com problemas, por favor tente novamente mais tarde.",
                                    this.btn_load = false;                  

                            }).finally(() => {

                                    if(sessionStorage.getItem('token') == 'null'){
                                        aux = false;
                                    }
                                  
                                    if(aux){
                                            if(this.cliente_select.PrefixoCelular+""+this.cliente_select.Celular+""+this.cliente_select.PrefixoTelefone+""+this.cliente_select.Telefone != this.cliente_update.PrefixoCelular+""+this.cliente_update.Celular+""+this.cliente_update.PrefixoTelefone+""+this.cliente_update.Telefone){
                                                this.pontosTelefone();
                                            }
                                            if(this.cliente_select.Email != this.cliente_update.Email){
                                                if(this.isEmptyObject(this.cliente_update.Email) === false){
                                                     this.pontosEmail();
                                                }
                                            }
                                        }
                                        
                                   this.particicparSorteio(this.cliente_update);
                            });

                    }
                },

                finalizar(){                    
                       this.$router.push('/home');
                }
        
         }
       
    }

</script>

<style scoped>
    .outine-2 {
        border: 2px solid white;
    }

    .card--flex-toolbar {
        margin-top: -124px;
    }

    .learn-more-btn {
        text-transform: initial;
        text-decoration: underline;
    }
</style>